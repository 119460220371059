import React from "react";
import classes from "./Hero.module.css";
const Hero = () => {
  return (
    <section className={classes.section}>
      <div className={classes.leftSide}>
        <h1>Unlock the convenience of a USD wallet today</h1>
        <div className={classes.subText}>
          <h4>
            Getting started is a breeze! Streamline your in-app transactions
            like never before!
          </h4>
        </div>

        <div className={classes.buttons}>
          <button className={classes.iphone}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-brand-apple"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z" />
              <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
            </svg>{" "}
            Get on iPhone
          </button>
          <button className={classes.android}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-brand-android"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 10l0 6" />
              <path d="M20 10l0 6" />
              <path d="M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0" />
              <path d="M8 3l1 2" />
              <path d="M16 3l-1 2" />
              <path d="M9 18l0 3" />
              <path d="M15 18l0 3" />
            </svg>
            Get on Android
          </button>
        </div>
      </div>

      <div className={classes.imgBox}>
        <img
          src="https://res.cloudinary.com/da3avivzn/image/upload/v1699116785/oketelan_k1uau1.png"
          alt=""
        />
      </div>
    </section>
  );
};

export default Hero;
