import React from "react";
import classes from "./Navigation.module.css";
const Navigation = () => {
  return (
    <section className={classes.section}>
      <div>
        <img
          src="https://res.cloudinary.com/da3avivzn/image/upload/v1699110632/oketelogo_dwgplu.png"
          alt=""
        />
      </div>
    </section>
  );
};

export default Navigation;
